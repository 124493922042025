import { useState, cloneElement } from "react";
import { HashLink } from "react-router-hash-link";
import {
    AppBar,
    Box,
    Button,
    Container,
    IconButton,
    Link,
    Menu,
    MenuItem,
    Toolbar,
    Typography,
    useScrollTrigger,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

const NavbarScroll = (props) => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 2,
    });

    return cloneElement(props.children, {
        elevation: trigger ? 2 : 0,
        style: {
            background: trigger ? "black" : "none",
            transition: trigger ? "0.3s" : "0.0s",
        },
    });
};

const Navbar = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 2,
    });

    const pages = [
        {
            name: "Sobre",
            link: "/#about",
        },
        {
            name: "Soluções",
            link: "/#services",
        },
        {
            name: "Copyfolio",
            link: "/#copys",
        },
        {
            name: "Especialidades",
            link: "/#specs",
        },
        {
            name: "Contato",
            link: "/#contact",
        },
    ];

    return (
        <NavbarScroll>
            <AppBar
                position="fixed"
                sx={{ bgcolor: "black", color: "white", p: 1, top: -2 }}
            >
                <Container maxWidth={false}>
                    <Toolbar disableGutters>
                        <Box
                            component={HashLink}
                            to="/"
                            sx={{
                                mt: "auto",
                                mb: "auto",
                                mr: 2,
                                pb: 0.5,
                            }}
                        >
                            <img
                                src={require("../img/king_icon.png")}
                                height="36em"
                            />
                        </Box>

                        <Typography
                            component={HashLink}
                            to="/"
                            variant="h5"
                            sx={{
                                textDecoration: "none",
                                color: "white",
                                mt: "auto",
                                mb: "auto",
                                "&:hover": {
                                    color: "white",
                                },
                            }}
                        >
                            Marcelo <strong>Sanhudo</strong>
                        </Typography>

                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "none", md: "flex" },
                                justifyContent: "end",
                            }}
                        >
                            {pages.map((item) => (
                                <HashLink
                                    smooth
                                    to={item.link}
                                    className="hash-link"
                                >
                                    <Button sx={{ color: "white" }}>
                                        {item.name}
                                    </Button>
                                </HashLink>
                            ))}
                            <Link
                                className="hash-link"
                                href="https://labtxia.blogspot.com"
                                target="_blank"
                            >
                                <Button sx={{ color: "white" }}>Blog</Button>
                            </Link>
                        </Box>

                        <Box
                            sx={{
                                flexGrow: 1,
                                display: { xs: "flex", md: "none" },
                                justifyContent: "end",
                            }}
                        >
                            <IconButton
                                sx={{ color: "inherit" }}
                                onClick={handleOpenNavMenu}
                            >
                                <MenuIcon fontSize="large" />
                            </IconButton>

                            <Menu
                                anchorEl={anchorElNav}
                                keepMounted
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "left",
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{ display: { xs: "block", md: "none" } }}
                            >
                                {pages.map((item) => (
                                    <HashLink
                                        smooth
                                        to={item.link}
                                        className="hash-link"
                                    >
                                        <MenuItem
                                            sx={{ color: "black" }}
                                            onClick={handleCloseNavMenu}
                                        >
                                            {item.name}
                                        </MenuItem>
                                    </HashLink>
                                ))}
                                <Link
                                    href="https://labtxia.blogspot.com"
                                    target="_blank"
                                    className="hash-link"
                                >
                                    <MenuItem
                                        sx={{
                                            color: "black",
                                            textDecoration: "none",
                                        }}
                                        onClick={handleCloseNavMenu}
                                    >
                                        Blog
                                    </MenuItem>
                                </Link>
                            </Menu>
                        </Box>
                    </Toolbar>
                </Container>
            </AppBar>
        </NavbarScroll>
    );
};

export default Navbar;
