import {
    createTheme,
    responsiveFontSizes,
    experimental_sx as sx
} from "@mui/material/styles";
import { amber, teal } from "@mui/material/colors";

let theme = createTheme({
    typography: {
        body1: {
            fontSize: "1.4rem"
        },
        body2: {
            fontSize: "1.2rem"
        }
    },
    palette: {
        primary: {
            main: teal[400],
            dark: teal[500]
        },
        secondary: {
            main: "#ffffff",
            dark: "#ffffff"
        }
    },
    shadows: [
        "none",
        "none",
        ...Array(23).fill("0px 0px 15px rgba(0, 0, 0, 0.5)")
    ],
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        '&:hover fieldset': {
                            border: "none"
                        },
                        '&.Mui-focused fieldset': {
                            border: "2px solid " + teal[400]
                        },
                    },
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: sx({
                    borderRadius: "1.2em",
                    boxShadow: "0px 24px 24px rgba(0, 0, 0, 0.05)"
                })
            }
        },
        MuiButton: {
            styleOverrides: {
                root: sx({
                    borderRadius: "1.2em",
                    fontSize: "1.2em",
                    boxShadow: "0px 24px 24px rgba(0, 0, 0, 0.05)",
                    "&:hover": {
                        boxShadow: "0px 24px 24px rgba(0, 0, 0, 0.05)"
                    }
                })
            },
            variants: [
                {
                    props: { variant: "gradient" },
                    style: {
                        // background: "linear-gradient(45deg, #42A5F5, #2962FF)",
                        background: "linear-gradient(135deg, #7ef29d, #0f68a9)",
                        // backgroundColor: blue["A700"],
                        backgroundColor: "#0f68a9",
                        color: "white",
                        ":hover": {
                            // background: blue["A700"],
                            // backgroundColor: blue["A700"]
                            background: "#0f68a9",
                            backgroundColor: "#0f68a9"
                        }
                    }
                },
                {
                    props: { variant: "outlined" },
                    style: {
                        border: "1px solid"
                    }
                }
            ]
        }
    }
});

theme = responsiveFontSizes(theme);

export { theme };