import React from "react";
import { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Avatar, Box, Button, Card, CardHeader, CardMedia, CardContent, Container, Grid, Typography, Chip, Input, TextField, CircularProgress } from "@mui/material";
import { amber, grey, teal } from "@mui/material/colors";
import { Modal } from "@mui/material";

import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import QueryStatsOutlinedIcon from '@mui/icons-material/QueryStatsOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import NavigateBeforeOutlinedIcon from '@mui/icons-material/NavigateBeforeOutlined';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import Pdf from "../docs/sanhudo.pdf";

import { Carousel } from "react-bootstrap";
import { SettingsPhoneTwoTone } from "@mui/icons-material";

import full_texts from "../data/full_texts.json";
import work from "../data/work.json";

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LanguageIcon from '@mui/icons-material/Language';
import YouTubeIcon from '@mui/icons-material/YouTube';

import emailjs from "@emailjs/browser";

const cartas = [
    "Bares e Pubs",
    "Saúde e Estética",
    "Fitness e Bem-Estar",
    "Entretenimento e Lazer",
    "Análise de Dados",
    "Avaliação Ambiental",
    "Esportes",
    "Roteiros",
    "Gastronomia e Viagens",
    "Política e Social"
]

const texts = {
    "Bares e Pubs": {
        "img": "pub.jpg",
        "title": "Bares e Pubs",
        "content": "Para obter resultados expressivos nas redes, é essencial empregar técnicas eficazes, como o copywriting. Se você é dono ou gerente de bares ou pubs, familiarize-se com essa arte da escrita persuasiva voltada para a conversão."
    },
    "Saúde e Estética": {
        "img": "health.png",
        "title": "Saúde e Estética",
        "content": "Para alcançar resultados expressivos nas redes, é essencial aplicar técnicas eficazes, como o copywriting. Se você é proprietário ou gestor em saúde e estética, familiarize-se com a arte da escrita persuasiva voltada para a conversão.",
    },
    "Fitness e Bem-Estar": {
        "img": "gym.png",
        "title": "Fitness e Bem-Estar",
        "content": "Para obter resultados expressivos nas redes, é essencial empregar técnicas eficazes, como o copywriting. Se você é dono ou gestor de espaços voltados para fitness e qualidade de vida, familiarize-se com essa arte da escrita persuasiva voltada para a conversão.",
    },
    "Entretenimento e Lazer": {
        "img": "entert.png",
        "title": "Entretenimento e Lazer",
        "content": "Para conquistar resultados expressivos nas redes, é crucial empregar técnicas eficazes, como o copywriting. Se você é proprietário ou gestor na área de entretenimento e lazer, familiarize-se com essa arte da escrita persuasiva, especialmente desenvolvida para impulsionar conversões.",
    },
    "Análise de Dados":
    {
        "img": "data.png",
        "title": "Análise de Dados e Aprendizado de Máquina",
        "content": "Para alcançar resultados notáveis nas redes, é crucial empregar técnicas eficazes, como o copywriting. Se você está envolvido em Análise de Dados e Aprendizado de Máquina, compreenda a importância dessa arte de escrita persuasiva focada em conversão.",
    },
    "Avaliação Ambiental": {
        "img": "env.png",
        "title": "Mineração e Avaliação Ambiental",
        "content": "Para obter resultados expressivos nas redes, é essencial empregar técnicas eficazes, como o copywriting. Se você é dono ou gestor na área de Mineração e Avaliação Ambiental, familiarize-se com essa arte da escrita persuasiva voltada para a conversão.",
    },
    "Esportes": {
        "img": "sports.png",
        "title": "Esportes e Futebol",
        "content": "Para obter resultados expressivos nessas plataformas, é crucial empregar técnicas eficazes, como o copywriting. Se você é proprietário ou gestor de negócios relacionados a esportes e futebol, é fundamental entender essa arte da escrita persuasiva voltada para a conversão.",
    },
    "Roteiros": {
        "img": "script.png",
        "title": "Roteiros de Games, Filmes e Séries",
        "content": "Para obter resultados expressivos nas redes, é essencial empregar técnicas eficazes, como o copywriting. Se você está envolvido com roteiros de games, filmes e séries, familiarize-se com essa arte da escrita persuasiva voltada para a conversão.",
    },
    "Gastronomia e Viagens": {
        "img": "food.png",
        "title": "Gastronomia e Viagens",
        "content": "Para obter resultados expressivos nas redes, é essencial empregar técnicas eficazes, como o copywriting. Se você é proprietário ou gestor na área de gastronomia e viagens, familiarize-se com essa arte da escrita persuasiva voltada para a conversão.",
    },
    "Política e Social": {
        "img": "politics.png",
        "title": "Política e Bem-Estar Social",
        "content": "Para obter resultados expressivos nas redes, é essencial empregar técnicas eficazes, como o copywriting. Se você é político ou gestor de negócios sociais, familiarize-se com essa arte da escrita persuasiva voltada para a conversão.",
    },
}

const copys = [
    {
        img: "road.jpg",
        title: "Movimento social pela duplicação da RSC 287 - Facebook",
        text: [
            "Onde você estará em 20 anos?",
            "O tempo previsto para usufruir dos benefícios da duplicação da RSC 287 a partir de Santa Maria é de 20 anos!",
            "Você acha que a longa espera, o pagamento de pedágio e a duplicação da RSC 287 em 20 anos representam gestão pública eficiente?",
            "Siga nossas redes e acompanhe o movimento."
        ]
    },
    {
        img: "meeting.jpg",
        title: "Promoção de evento do Instituto Alta Política",
        text: [
            "Edição 40 do Café com Alta Política recebe os prefeitos de Vera Cruz, Restinga Seca e Mariana Pimentel para debater os caminhos para a retomada da economia, desenvolvimento regional e promoção do turismo. Apoio da FAMURS."
        ]
    },
    {
        img: "coffee.jpg",
        title: "Convite para Café com alta política",
        text: [
            'Vamos conversar sobre Porto Alegre? Você é nosso convidado para o próximo Café com Alta Política. Quinta-feira, dia 17, 8h30min. Presencial. O vereador Adeli Sell doará a cada participante um exemplar do seu livro "Certas Ruas de Porto Alegre".'
        ]
    },
    {
        img: "handshake.jpg",
        title: "Convite para Café com alta política",
        text: [
            "Para encerrar o ano de 2021 com perspectivas de um novo ano com grandes encontros, diálogos e reflexões o Café com Alta Política retoma suas atividades presenciais.",
            "No dia 22 de dezembro, receberemos o presidente do Progressistas do Rio Grande do Sul, Celso Bernardi. O encontro será às 8h30 no café do MARGS e o valor do café é de R$ 22,00."
        ]
    },
    {
        img: "book.jpg",
        title: "Promoção de livro para campanhas eleitorais",
        text: [
            "|E-BOOK|- MANUAL PRÁTICO PARA UMA CAMPANHA ELEITORAL EFICIENTE [2021-2022]",
            "Campanha Eleitoral (e pré-campanha): Por onde começar? Manual com orientações para planejamento e estruturação de uma campanha eleitoral (42 páginas). Aborda os aspectos necessários que devem ser observados para aqueles que pretendem concorrer em 2022. ||ADQUIRA JÁ O SEU||"
        ]
    },
    {
        img: "macbook.jpg",
        title: "Divulgação de serviços de pesquisa eleitoral",
        text: [
            "Você já pensou em usar análise de sentimento para definir suas estratégias políticas e/ou eleitorais?",
            "A análise de sentimento é uma técnica da mineração de dados. Sua finalidade é revelar a opinião das pessoas sobre temas específicos. Se bem aplicada essa técnica pode trazer vantagens inestimáveis a quem tem essas informações.",
            "A resposta da maioria das questões já está dada, basta que saibamos como extrair, organizar e interpretar essas respostas."
        ]
    },
    {
        img: "xbox.jpg",
        title: "Post de dicas - Instagram",
        text: [
            "A leitura é a fonte mais indicada para inspirações na criação de peças. Porém existem outras fontes que ajudam em nossos processos. Filmes e séries possuem inúmeras narrativas que podem servir como subsídio para novas ideias. Os games são outra forma de contar histórias e podem ser muito uteis para processos criativos. O velho e bom caderno de notas não pode ficar de fora. Observe e anote. Não deixe, por pior que pareça, a ideia ir embora. Um dia você vai usar."
        ]
    }
];

const CopyCard = props => {
    const [isExpanded, setIsExpanded] = useState(false);
    const handleCardClick = () => {
        setIsExpanded(!isExpanded);
    }

    return (
        <Card sx={{ minHeight: { md: ((isExpanded && "650px") || "475px") } }}>
            <CardMedia
                component="img"
                height="140"
                image={require("../img/" + props.item.img)}
            />
            <CardContent sx={{ p: 4 }}>
                <Typography variant="h4" sx={{ mb: 2 }}><strong>{props.item.title}</strong></Typography>
                {!isExpanded && (<Typography>{props.item.text.join(" ").split(" ").slice(0, 20).join(" ")} ...</Typography>)}
                {isExpanded && (<Typography>{props.item.text.join(" ")}</Typography>)}
                {!isExpanded && (
                    <Grid container justifyContent="center">
                        <Button
                            onClick={handleCardClick}
                            sx={{
                                mt: 1,
                                boxShadow: "none",
                                "&:hover": { boxShadow: "none" }
                            }}
                        >
                            Leia mais
                        </Button>
                    </Grid>
                )}
            </CardContent>
        </Card>
    )
}

const CarouselItem = props => {
    return (
        <Box sx={{ mt: 2, mb: 6, mr: 4, ml: 4 }}>
            <Card sx={{ minHeight: { xs: "100vh", md: "520px" }, boxShadow: "0px 24px 24px rgba(0, 0, 0, 0.05)" }}>
                <CardMedia
                    component="img"
                    height="140"
                    image={require("../img/" + props.img)}
                />
                <CardContent sx={{ p: 4, pl: { xs: 8, sm: 12, md: 16 }, pr: { xs: 8, sm: 12, md: 16 } }}>
                    <Typography variant="h4" sx={{ mb: 2 }}><strong>{props.title}</strong></Typography>
                    {props.text.map((item, index) => <Typography sx={{ fontSize: { xs: 14, md: 18 } }} key={index}>{item}</Typography>)}
                </CardContent>
            </Card>
        </Box>
    );
}

const SpecialtyCard = props => {
    return (
        <>
            <Grid item xs={12} md={4} >
                <Card sx={{ backgroundColor: "black" }}>
                    {/* <img src={require(`../img/${props.img}`)} /> */}
                    <Box
                        component="img"
                        src={require(`../img/${props.img}`)}
                        sx={{
                            height: "260px",
                            width: "100%",
                            objectFit: "cover"
                        }}
                    />
                </Card>
            </Grid>
            <Grid item xs={12} md={8} >
                <Typography variant="h4" sx={{ color: "white" }}><strong>{props.title}</strong></Typography>
                <Typography sx={{ color: "white", mt: 2 }}>
                    {props.content}
                </Typography>
                <Button size="large" variant="gradient"
                    sx={{
                        minWidth: { xs: "100%", md: "50%" },
                        paddingY: "8px",
                        mt: 3
                    }}
                    onClick={props.handleShow}
                >
                    Saiba mais
                </Button>
            </Grid>
        </>
    )
}

const Home = () => {
    const [readMoreTitle, setReadMoreTitle] = useState("Bares e Pubs");
    const [readMoreText, setReadMoreText] = useState(full_texts["Bares e Pubs"]);
    const [readMoreImg, setReadMoreImg] = useState("pub.jpg");

    const [numCopys, setNumCopys] = useState(2);
    const [show, setShow] = useState(false);

    const [numWork, setNumWork] = useState(4);

    const handleCopyButton = () => {
        if (numCopys < copys.length) {
            setNumCopys(numCopys + 2);
        }
    }

    const handleShow = (title, text, img) => {
        setReadMoreTitle(title);
        setReadMoreText(text);
        setReadMoreImg(img);
        setShow(true)
    };

    const handleClose = () => setShow(false);
    const [specialty, setSpecialty] = useState("Bares e Pubs");

    const modalStyle = {
        position: 'absolute',
        top: '5%',
        left: '5%',
        width: '90%',
        bgcolor: 'white',
        borderRadius: "1.2em",
        overflow: "scroll",
        height: '90%',
        display: 'block',

    }

    const ReadMore = () => {
        return (
            <Modal open={show} onClose={handleClose} sx={{ borderRadius: "1.2em" }}>
                <Box sx={modalStyle}>
                    <Box sx={{ position: "relative" }}>
                        <CardMedia
                            component="img"
                            height="200"
                            image={require(`../img/${readMoreImg}`)}
                            sx={{
                                filter: "brightness(0.5) contrast(1.1)",
                            }}
                        />

                        <Typography variant="h3" sx={{
                            position: "absolute",
                            bottom: "24px",
                            left: "24px",
                            paddingRight: "24px",
                            color: "white",
                        }}>
                            <strong>Copy para {readMoreTitle}</strong>
                        </Typography>
                    </Box>
                    <Grid container>
                        <Box sx={{ padding: "36px" }}>
                            {readMoreText.map((item, index) => (
                                <Grid item xs={12} sm={10} lg={11} key={index}>
                                    <Typography mt={2}>
                                        {item}
                                    </Typography>
                                </Grid>
                            ))}
                            <Grid container spacing={2} mt={2}>
                                <Grid item xs={12} md={6} lg={3}>
                                    <HashLink smooth to="/#copys" className="hash-link">
                                        <Button variant="outlined"
                                            sx={{
                                                width: "100%",
                                                boxShadow: "none",
                                                "&:hover": {
                                                    boxShadow: "none"
                                                }
                                            }} onClick={() => setShow(false)}
                                        >
                                            Conheça meu trabalho
                                        </Button>
                                    </HashLink>
                                </Grid>
                                <Grid item xs={12} md={6} lg={3}>
                                    <Button variant="gradient" startIcon={<WhatsAppIcon />}
                                        sx={{
                                            paddingX: "16px",
                                            color: "white !important",
                                            width: "100%"
                                        }}
                                        href="https://api.whatsapp.com/send?phone=5551989921313"
                                        target="_blank"
                                    >
                                        Entre em Contato
                                    </Button>
                                </Grid>
                            </Grid>
                        </Box>
                    </Grid>
                </Box>
            </Modal >
        );
    }

    const [formState, setFormState] = useState({
        reply_to: "",
        from_name: "",
        message: ""
    });
    const [isWaiting, setIsWaiting] = useState(false);
    const [messageSent, setMessageSent] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidName, setIsValidName] = useState(true);
    const [isValidMessage, setIsValidMessage] = useState(true);

    const submitHandler = async (event) => {
        event.preventDefault();
        const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
        const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
        const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;
        if (!messageSent && !isWaiting) {
            try {
                let isValid = true;
                if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formState.reply_to)) {
                    isValid = false;
                    setIsValidEmail(false);
                }

                if (formState.message.length === 0) {
                    isValid = false;
                    setIsValidMessage(false);
                }

                if (formState.from_name.length === 0) {
                    isValid = false;
                    setIsValidName(false);
                }

                if (isValid && !isWaiting) {
                    // function sleep() {
                    //     return new Promise(resolve => setTimeout(resolve, 1500));
                    // }
                    setIsWaiting(true);
                    setMessageSent(false);
                    // await sleep();
                    await emailjs.send(serviceId, templateId, formState, publicKey);
                    setIsWaiting(false);
                    setMessageSent(true);
                }
            } catch (error) {
                console.log(error);
            }
        }
    };

    const inputChangeHandler = event => {
        setMessageSent(false);
        if (event.target.name === "from_name") setIsValidName(true);
        if (event.target.name === "reply_to") setIsValidEmail(true);
        if (event.target.name === "message") setIsValidMessage(true);
        setFormState({
            ...formState,
            [event.target.name]: event.target.value
        });
    };

    return (
        <React.Fragment>
            <ReadMore title={readMoreTitle} text={readMoreText} />
            <section id="home" className="anchor-id" />
            <Grid container className="hero-image">
                <Container
                    className="hero-title"
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center"
                    }}
                >
                    <Typography variant="h1" sx={{ color: "white", fontFamily: "Roboto" }}>
                        Marcelo <span style={{ fontWeight: "bold" }}>Sanhudo</span>
                    </Typography>
                    <Typography variant="h4" sx={{ color: "white" }}>
                        Copywriter & Cientista Social
                    </Typography>
                    <Grid container spacing={2} mt={3}>
                        <Grid item xs={12} md={3}>
                            <HashLink smooth to="/#about" className="hash-link">
                                <Button color="secondary" size="large" variant="outlined"
                                    sx={{
                                        minWidth: "100%"
                                    }}
                                >
                                    Saiba mais
                                </Button>
                            </HashLink>
                        </Grid>
                        <Grid item xs={12} md={3}>
                            <HashLink smooth to="/#contact" className="hash-link">
                                <Button size="large" variant="gradient"
                                    sx={{
                                        minWidth: "100%",
                                        paddingY: "8px"
                                    }}
                                >
                                    Contato
                                </Button>
                            </HashLink>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <section id="about" className="anchor-id" />
            <Grid container mt={6} mb={6}>
                <Container>
                    <Typography variant="h2" sx={{ textAlign: "center", mb: 4, fontWeight: "bold" }}>Eu, um copy</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={5} sx={{ display: "flex", justifyContent: { xs: "center" } }}>
                            <Avatar
                                src={require("../img/marcelo.jpeg")}
                                sx={{ width: 256, height: 256, filter: "saturate(0) contrast(1.1)" }}
                            />
                        </Grid>
                        <Grid item xs={12} md={7} sx={{ mt: "auto", mb: "auto" }}>
                            <Typography>
                                Sou <strong>Cientista Social</strong> e <strong>Mestre em Antropologia</strong>. Especialista em <strong>Marketing e Estratégia Eleitoral</strong>. Pesquisador com mais de 15 anos de experiência em pesquisas públicas e privadas. Utilizo meu conhecimento e <strong>know-how em pesquisas</strong> humanas, sociais e de mercado em diversos níveis - do design até o produto final - para embasar processos de <strong>copywriting</strong> criativos e originais.
                            </Typography>
                            <Box sx={{ mt: 2 }}>
                                <Button
                                    variant="outlined"
                                    component="a"
                                    href={Pdf}
                                    sx={{
                                        boxShadow: "none",
                                        minWidth: { xs: "100%", md: "30%" },
                                        "&:hover": {
                                            color: teal[500],
                                            boxShadow: "none"
                                        }
                                    }}
                                >
                                    Leia mais
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Grid>

            <section id="services" className="anchor-id" />
            <Grid container sx={{ backgroundColor: "#fafafa", pt: 6, pb: 6 }}>
                <Container>
                    <Typography variant="h2" sx={{ textAlign: "center", mb: 4, fontWeight: "bold" }}>Soluções</Typography>
                    <Grid container spacing={2}>

                        <Grid item xs={12} md={6}>
                            <AnimationOnScroll
                                animateIn="animate__fadeIn"
                                duration={1}
                                animateOnce={true}
                            >
                                <Card sx={{ p: 2, minHeight: { md: "400px" }, boxShadow: "none" }}>
                                    <CardContent>
                                        <FindInPageOutlinedIcon sx={{ fontSize: 72, color: teal["A700"], mb: 1 }} />
                                        <Typography variant="h4" sx={{ mb: 1 }}><strong>Pesquisa para Copy</strong></Typography>
                                        <Typography>Pesquisas criativas que <strong>vão além dos dados quantitativos</strong>. Uso de técnicas de pesquisas oriundas da Antropologia (etnografia, diário de experiências e netnografia) para <strong>design de produtos</strong> e serviços com foco em copys altamente relevantes e de resultado.</Typography>
                                    </CardContent>
                                </Card>
                            </AnimationOnScroll>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <AnimationOnScroll
                                animateIn="animate__fadeIn"
                                duration={1}
                                animateOnce={true}
                            >
                                <Card sx={{ p: 2, minHeight: { md: "400px" }, boxShadow: "none" }}>
                                    <CardContent>
                                        <NoteAltOutlinedIcon sx={{ fontSize: 72, color: teal["A700"], mb: 1 }} />
                                        <Typography variant="h4" sx={{ mb: 1 }}><strong>Copy</strong></Typography>
                                        <Typography>Inteligência em copywriting para elaboração das <strong>melhores estratégias</strong> de roteirização de peças multiníveis para suas campanhas.</Typography>
                                    </CardContent>
                                </Card>
                            </AnimationOnScroll>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <AnimationOnScroll
                                animateIn="animate__fadeIn"
                                duration={1}
                                animateOnce={true}
                            >
                                <Card sx={{ p: 2, minHeight: { md: "400px" }, boxShadow: "none" }}>
                                    <CardContent>
                                        <InsertChartOutlinedIcon sx={{ fontSize: 72, color: teal["A700"], mb: 1 }} />
                                        <Typography variant="h4" sx={{ mb: 1 }}><strong>Copy Político e Eleitoral</strong></Typography>
                                        <Typography>Através de <strong>análises de redes sociais</strong> e de pesquisas imersivas elaboramos as <strong>melhores peças publicitárias</strong> para alcançar seus potenciais eleitores e convertê-los em <strong>apoio eleitoral e político</strong>.</Typography>
                                    </CardContent>
                                </Card>
                            </AnimationOnScroll>
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <AnimationOnScroll
                                animateIn="animate__fadeIn"
                                duration={1}
                                animateOnce={true}
                            >
                                <Card sx={{ p: 2, minHeight: { md: "400px" }, boxShadow: "none" }}>
                                    <CardContent>
                                        <QueryStatsOutlinedIcon sx={{ fontSize: 72, color: teal["A700"], mb: 1 }} />
                                        <Typography variant="h4" sx={{ mb: 1 }}><strong>Análise de dados</strong></Typography>
                                        <Typography>Mineração de dados e <strong>técnicas computacionais</strong> para descobrir tendências em bancos de dados. Combinamos ferramentas matemáticas com <strong>inovações tecnológicas</strong> - inteligência artificial e o machine learning. Clareza e efetividade na hora de definir áreas de ação, ou seja, <strong>público alvo</strong>.</Typography>
                                    </CardContent>
                                </Card>
                            </AnimationOnScroll>
                        </Grid>

                    </Grid>
                </Container>
            </Grid>

            <section id="specs" className="anchor-id" />
            <Grid container sx={{ backgroundColor: 'black', pt: 6, pb: 6 }}>
                <Container>
                    <Typography variant="h2" sx={{ color: 'white', textAlign: "center", fontWeight: "bold", mb: 4 }}>Especialidades</Typography>
                    {cartas.map((item, index) => (
                        <Chip
                            onClick={() => setSpecialty(item)}
                            label={item} sx={{
                                color: (specialty === item && "black" || "white"),
                                border: "1px solid white",
                                mr: 1,
                                mb: 1,
                                backgroundColor: (specialty === item && "white"),
                                cursor: "pointer",
                                "&:hover": {
                                    backgroundColor: (specialty !== item && "rgba(255,255,255,0.1)" || "white"),
                                }
                            }} key={index} />
                    ))}
                    <Grid container spacing={4} mt={1}>
                        <SpecialtyCard handleShow={() => handleShow(texts[specialty]["title"], full_texts[texts[specialty]["title"]], texts[specialty]["img"])} content={texts[specialty]["content"]} img={texts[specialty]["img"]} title={texts[specialty]["title"]} />
                    </Grid>
                </Container>
            </Grid>

            <section id="copys" className="anchor-id" />
            <Grid container sx={{ pt: 6, pb: 6 }}>
                <Container>
                    <Typography variant="h2" sx={{ textAlign: "center", fontWeight: "bold", mb: 4 }}>Conheça meu Trabalho</Typography>
                    <Grid container spacing={2}>
                        {work.slice(0, numWork).map((item, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                <AnimationOnScroll
                                    animateIn="animate__fadeIn"
                                    duration={1}
                                    animateOnce={true}
                                >
                                    <a href={item.link} target="_blank" style={{ textDecoration: "none" }}>
                                        <Card className="work-card" sx={{
                                            minHeight: "120px",
                                            "&:hover": {
                                                boxShadow: "0px 24px 24px rgba(0, 0, 0, 0.1)",
                                                cursor: "pointer"
                                            }
                                        }}>
                                            <CardContent>
                                                <Grid container direction="row" justifyContent="center" alignItems="center">
                                                    <Grid item xs={2}>
                                                        {(item.type === "instagram" && <InstagramIcon className="card-icon" sx={{ color: "black", fontSize: 64 }} />)
                                                            || (item.type === "facebook" && <FacebookIcon className="card-icon" sx={{ color: "black", fontSize: 64 }} />)
                                                            || (item.type === "website" && <LanguageIcon className="card-icon" sx={{ color: "black", fontSize: 64 }} />)
                                                            || (item.type === "youtube" && <YouTubeIcon className="card-icon" sx={{ color: "black", fontSize: 64 }} />)
                                                        }

                                                    </Grid>
                                                    <Grid item xs={10} pl={2}>
                                                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                                                            {item.title}
                                                        </Typography>
                                                        <Typography sx={{ fontSize: "16px !important" }}>
                                                            {item.content}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                            </CardContent>
                                        </Card>
                                    </a>
                                </AnimationOnScroll>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid container justifyContent="center" mt={4}>
                        <Button
                            size="large"
                            variant="outlined"
                            onClick={() => setNumWork(numWork + 4)}
                            disabled={numWork > work.length}
                            sx={{
                                minWidth: { xs: "100%", md: "30%" },
                                color: (numWork >= work.length && grey[300]),
                                border: "1px solid",
                                boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
                                "&:hover": {
                                    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)"
                                }
                            }}
                            startIcon={<KeyboardArrowDownIcon />}
                        >
                            Veja mais
                        </Button>
                    </Grid>

                    {/* <Typography variant="h2" sx={{ textAlign: "center", fontWeight: "bold", mb: 4, mt: 4 }}>Copyfólio</Typography>
                    <Grid container spacing={2}>
                        {copys.slice(0, numCopys).map((item, index) => (
                            <Grid item xs={12} md={6} key={index}>
                                <AnimationOnScroll
                                    animateIn="animate__fadeIn"
                                    duration={1}
                                    animateOnce={true}
                                >
                                    <CopyCard item={item} />
                                </AnimationOnScroll>
                            </Grid>
                        ))}
                    </Grid>

                    <Grid container justifyContent="center" mt={4}>
                        <Button
                            size="large"
                            variant="outlined"
                            onClick={handleCopyButton}
                            disabled={numCopys > copys.length}
                            sx={{
                                minWidth: { xs: "100%", md: "30%" },
                                color: (numCopys >= copys.length && grey[300]),
                                boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
                                "&:hover": {
                                    boxShadow: "0px 0px 0px rgba(0, 0, 0, 0)"
                                },
                                border: "1px solid"
                            }}
                            startIcon={<KeyboardArrowDownIcon />}
                        >
                            Veja mais
                        </Button>
                    </Grid> */}
                </Container>
            </Grid >


            <section id="contact" className="anchor-id" />
            <Grid container sx={{ backgroundColor: "#000000", pt: 6, pb: 6 }}>
                <Container>
                    <Typography variant="h2" sx={{ color: "white", textAlign: "center", mb: 2, fontWeight: "bold" }}>Contato</Typography>
                    <Typography sx={{ color: "white", mb: 1, textAlign: "center" }}>
                        Vamos realizar nossos sonhos?
                    </Typography>

                    <TextField
                        error={!isValidName}
                        name="from_name"
                        placeholder="Nome"
                        InputProps={{ sx: { borderRadius: "0.6em", backgroundColor: "white" } }}
                        fullWidth
                        variant="outlined"
                        sx={{ mt: 2 }}
                        size="small"
                        onChange={inputChangeHandler}
                    />
                    <TextField
                        error={!isValidEmail}
                        name="reply_to"
                        InputProps={{ sx: { borderRadius: "0.6em", backgroundColor: "white" } }}
                        fullWidth
                        placeholder="E-mail"
                        variant="outlined"
                        sx={{ mt: 2 }}
                        size="small"
                        onChange={inputChangeHandler}
                    />
                    <TextField
                        error={!isValidMessage}
                        name="message"
                        InputProps={{ sx: { borderRadius: "0.6em", backgroundColor: "white" } }}
                        fullWidth
                        placeholder="Mensagem"
                        multiline
                        rows={4}
                        variant="outlined"
                        sx={{ mt: 2 }}
                        size="small"
                        onChange={inputChangeHandler}
                    />

                    {(!isValidMessage || !isValidEmail || !isValidMessage) && (
                        <Typography sx={{ color: "#f44336", mt: 1, fontSize: "16px !important" }}>
                            Preencha corretamente os campos acima.
                        </Typography>
                    )}
                    <Grid container justifyContent={"center"}>
                        <Button
                            variant="gradient"
                            size="large"
                            sx={{
                                mt: 2,
                                minWidth: { xs: "100%", md: "30%" }
                            }}
                            onClick={submitHandler}
                        >
                            Enviar
                        </Button>
                    </Grid>

                    {isWaiting && (
                        <Grid item xs={12} align="center" mt={2}>
                            <CircularProgress align="center" />
                        </Grid>
                    )}

                    {messageSent && (
                        <Grid item xs={12} mt={2}>
                            <Typography sx={{ color: "white" }} align="center">
                                Mensagem enviada com sucesso!
                            </Typography>
                        </Grid>
                    )}

                    <Grid container mt={5}>
                        <Grid item xs={12} md={5}>
                            <Grid container sx={{ mt: 1 }}>
                                <Grid item sx={{ mr: 2 }}>

                                    <Grid container direction="row" alignItems="center">

                                        <Grid item>
                                            <EmailOutlinedIcon sx={{ color: "white", mr: 1 }} />
                                        </Grid>
                                        <Grid item>
                                            <Typography sx={{ color: "white" }}>
                                                m.sanhudo@yahoo.com.br
                                            </Typography>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={4} display="flex" justifyContent="center">
                            <Grid container sx={{ mt: 1 }}>
                                <Grid item sx={{ mr: 2 }}>

                                    <Grid container direction="row" alignItems="center" mb={2}>
                                        <Grid item>
                                            <LocalPhoneOutlinedIcon sx={{ color: "white", mr: 1 }} />
                                        </Grid>
                                        <Grid item>
                                            <Typography sx={{ color: "white" }}>
                                                (51) 989921313
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} md={3}>
                            <a href="https://www.instagram.com/sanhudo_copywriter/" target="_blank">
                                <img src={require("../img/insta.png")} height={36} />
                            </a>
                            &emsp;
                            <a href="https://api.whatsapp.com/send?phone=5551989921313" target="_blank">
                                <img src={require("../img/whats.png")} height={36} />
                            </a>
                            &emsp;
                            <a href="https://www.linkedin.com/in/marcelo-sanhudo-a10900237/" target="_blank">
                                <img src={require("../img/linkedin.png")} height={36} />
                            </a>
                        </Grid>
                    </Grid>
                </Container>
            </Grid >
        </React.Fragment >
    );
}

export default Home;